/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { checkIsActive } from "../../../../_helpers";
import { useHistory } from "react-router-dom";
import { setRightSideBar } from "../../../../../app/modules/_redux/commonActions";
import * as actions from "../../../../../app/modules/_redux/commonActions";
import { useDispatch, useSelector } from "react-redux";
import HeaderUserDetails from "./HeaderUserDetails";
import { Badge, Button, Tooltip } from "@material-ui/core";
import { NotificationsOutlined } from "@material-ui/icons";
import { approvedRequests } from "../../../../../app/modules/dashboard/components/dummydata/dummydata";

export function HeaderMenu({ layoutProps, setComponent }) {
  const { isRightSidebar, viewSideBar } = useSelector((state) => ({
    isRightSidebar: state.common.isRightSidebar,
    viewSideBar: state.common.isVisibleSideBar
  }));
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const hideSideBar = () => {
    dispatch(actions.viewSideBar(false));
  };

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.header }),
  //   shallowEqual
  // );
  // console.log('currentState', currentState)
  // const branch = currentState?.entities?.title && currentState.entities.title;
  // console.log("branch", branch);

  return (
    <div
      id="kt_header_menu"
      class="header-menu header-menu-mobile header-menu-layout-default d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center w-auto w-lg-100"
    >
      <div class="brand flex-column-auto py-4 px-8" id="kt_brand">
        <a
          onClick={() => {
            history.push("/dashboard/view");
            dispatch(setRightSideBar(true));
          }}
          class="brand-logo cursor-pointer"
        >
          {window.location.pathname == "/dashboard/view" ? (
            <img
              alt="Logo"
              src="/media/logo/white.svg"
              class="max-h-30px mr-8 mt-2"
            />
          ) : (
            <img
              alt="Logo"
              src="/media/logo/Blue.svg"
              class="max-h-30px mr-8 mt-2"
            />
          )}
        </a>
      </div>
      <ul class="menu-nav d-none ">
        <li
          class={`menu-item h-80px menu-item-open menu-item-not-hightlighted ${getMenuItemActive(
            "/dashboard/view",
            true
          )} ${getMenuItemActive("/dashboard/view#", true)}`}
          aria-haspopup="true"
        >
          <a
            class="menu-link active h-80px text-uppercase "
            onClick={() => {
              history.push("/dashboard/view");
              dispatch(setRightSideBar(true));
            }}
            aria-current="page"
          >
            <span class="menu-text">Home</span>
          </a>
        </li>

        <li
          class={`menu-item h-80px menu-item-submenu ${getMenuItemActive(
            "/activeCampaign",
            true
          )} ${getMenuItemActive(
            "/completedCampaign",
            true
          )} ${getMenuItemActive("/pendingCampaign", true)}`}
          data-menu-toggle="click"
          aria-haspopup="true"
        >
          <a
            class="menu-link cursor-pointer h-80px"
            onClick={() => history.push(`/activeCampaign`)}
          >
            <span class="menu-text">Discover</span>
          </a>
        </li>
        {/* <li
          class={`menu-item h-80px ${getMenuItemActive(
            "/applications/list",
            true
          )} ${getMenuItemActive("/applications/list#", true)}`}
          aria-haspopup="true"
        >
          <a
            class="menu-link h-80px cursor-pointer"
            onClick={() => history.push("/applications/list")}
          >
            <span class="menu-text ">Sponsers</span>
          </a>
        </li> */}
        <li class="menu-item btn disabled d-none" aria-haspopup="true">
          <a class="menu-link btn disabled">
            <span class="font-weight-SemiBold">Proposals</span>
          </a>
        </li>
        <li class="menu-item btn disabled d-none" aria-haspopup="true">
          <a class="menu-link btn disabled">
            <span class="">Services</span>
          </a>
        </li>
        <li
          class={`menu-item h-80px ${getMenuItemActive(
            "/post-analytics",
            true
          )} ${getMenuItemActive("/post-analytics#", true)}`}
          aria-haspopup="true"
        >
          <a
            class="menu-link h-80px"
            onClick={() => history.push("/post-analytics")}
          >
            <span class="menu-text ">Proposals</span>
          </a>
        </li>
      </ul>

      <div className="row visible_toggle align-items-center">
        <Tooltip title="Requests" arrow>
          <div
            className="headerbutton"
            onClick={() => dispatch(setRightSideBar(!isRightSidebar))}
          >
            <Badge badgeContent={approvedRequests.length} color="secondary"  >
              <NotificationsOutlined />
            </Badge>
          </div>
        </Tooltip>
        <HeaderUserDetails setComponent={setComponent} />
      </div>
    </div>
  );
}
