import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  listNotifications,
  updateUnreadNotificationCount
} from "../../extras/dropdowns/notifications/_redux/NotificationActions";
import * as actions from "../../../../../app/modules/_redux/commonActions";
import { setRightSideBar } from "../../../../../app/modules/_redux/commonActions";
import { VerifiedUserRounded } from "@material-ui/icons";
import { Avatar, Tooltip } from "@material-ui/core";

function HeaderUserDetails({ setComponent }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    profileToEdit,
    viewSideBar,
    loggedInUserId,
    unreadNotificationCounts,
    isRightSidebar
  } = useSelector((state) => ({
    profileToEdit: state.common.profileToEdit,
    viewSideBar: state.common.isVisibleSideBar,
    loggedInUserId: state.auth.user.userId,
    unreadNotificationCounts: state.notificationModule.unreadNotificationCount,
    isRightSidebar: state.common.isRightSidebar
  }));

  const hideSideBar = () => {
    dispatch(actions.viewSideBar(false));
  };

  const listNotificationData = () => {
    dispatch(
      listNotifications({
        page: 1,
        pageSize: 50,
        loggedInUserId,
        appInfo: "BRAND"
      })
    ).then(() => dispatch(updateUnreadNotificationCount(0)));
  };

  return (
    <div>
      <div class="sidebar-header flex-column-auto px-lg-4 d-md-flex rounded-3">
        <div class="d-flex w-100">
          <Tooltip title="Profile" arrow>
            <div
              className="profileicon"
              onClick={() => {
                history.push("/user-profile/personal-information");
                viewSideBar && hideSideBar();
              }}
            >
              <Avatar
                sizes="large"
                src={
                  profileToEdit?.profilePic
                    ? `${process.env.REACT_APP_AWS_S3_BASE_URL +
                        process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                        "/" +
                        profileToEdit?.profilePic}`
                    : "/media/dummyImages/no_preview_old.png"
                }
              />
            </div>
          </Tooltip>
          <div class="quick-search quick-search-inline flex-grow-1 d-none">
            <div class="d-flex align-items-center mb-0">
              <div class="symbol symbol-50 flex-shrink-0 mr-2 cursor-pointer">
                <img
                  class="bg-white-o-80 symbol-label"
                  onClick={() => {
                    history.push("/user-profile/personal-information");
                    viewSideBar && hideSideBar();
                  }}
                  src={
                    profileToEdit?.profilePic
                      ? `${process.env.REACT_APP_AWS_S3_BASE_URL +
                          process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                          "/" +
                          profileToEdit?.profilePic}`
                      : "/media/dummyImages/no_preview_old.png"
                  }
                  style={{
                    objectFit: "cover",
                    border: "1px solid #dbdbdb63"
                  }}
                  alt="photo"
                />
              </div>
              <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                <a
                  onClick={() => {
                    history.push("/user-profile/personal-information");
                    viewSideBar && hideSideBar();
                  }}
                  class="text-dark text-hover-primary mb-0 font-size-md font-weight-bolder cursor-pointer"
                >
                  {profileToEdit?.companyName}
                </a>
                <span class="text-dark-50 font-size-xs">15 Apr 2022</span>
              </div>
            </div>
          </div>

          <div class="dropdown dropdown-inline view_mob">
            <a
              href="#"
              class="btn btn-icon btn-light  ml-3 h-40px w-40px flex-shrink-0"
              onClick={() => {
                hideSideBar();
              }}
            >
              <span class="svg-icon svg-icon-lg">
                <img
                  class="h-20px"
                  src="/media/svg/icons/custom/Icon_close.svg"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderUserDetails;
