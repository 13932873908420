import React, { useEffect, useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import { useDispatch } from "react-redux";
//import * as actions from './_redux/HeaderActions'
export function Header({ setComponent }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    //dispatch(actions.getBranch());
  }, []);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);


  return (
    <>
      {window.location.pathname == "/dashboard/view" ? (
        <>
          {" "}
          {/*begin::Header*/}
          <div
            className={`header top-adjust position-absolute ${layoutProps.headerClasses} w-100`}
            id="kt_header"
            {...layoutProps.headerAttributes}
          >
            {/*begin::Container*/}
            <div
              className={` ${layoutProps.headerContainerClasses} header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center pl-6 w-100 `}
            >
              <AnimateLoading />
              {/*begin::Header Menu Wrapper*/}
              {layoutProps.menuHeaderDisplay && (
                <HeaderMenuWrapper setComponent={setComponent} />
              )}
              {!layoutProps.menuHeaderDisplay && <div />}
              {/*end::Header Menu Wrapper*/}

              {/*begin::Topbar*/}
              <Topbar />
              {/*end::Topbar*/}
            </div>
            {/*end::Container*/}
          </div>
          {/*end::Header*/}
        </>
      ) : (
        <>
          {" "}
          {/*begin::Header*/}
          <div
            className={`header top-adjust ${layoutProps.headerClasses} w-100 `}
            id="kt_header"
            {...layoutProps.headerAttributes}
          >
            {/*begin::Container*/}
            <div
              className={` ${layoutProps.headerContainerClasses} header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center pl-6 w-100 shadow-sm bg-white `}
            >
              <AnimateLoading />
              {/*begin::Header Menu Wrapper*/}
              {layoutProps.menuHeaderDisplay && (
                <HeaderMenuWrapper setComponent={setComponent} />
              )}
              {!layoutProps.menuHeaderDisplay && <div />}
              {/*end::Header Menu Wrapper*/}

              {/*begin::Topbar*/}
              <Topbar />
              {/*end::Topbar*/}
            </div>
            {/*end::Container*/}
          </div>
          {/*end::Header*/}
        </>
      )}
    </>
  );
}
