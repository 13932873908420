import React, { useState } from "react";
import "./RequestForm.css"; // Create a separate CSS file for additional styling
import { useHistory } from "react-router-dom";
import { Toaster } from "../../../components/Toaster";
import { Avatar, Button, Chip, IconButton, TextField } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { sendMailToDms } from "../../campaignApplications/_redux/applicationCrud";
import { useSelector } from "react-redux";

function RequestForm() {
  const [link, setLink] = useState("");
  const [links, setLinks] = useState([]);
  const [references, setReferences] = useState([]);
  const history = useHistory();
  const [workDetails, setWorkDetails] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [expectedDate, setExpectedDate] = useState("");
  const profileToEdit = useSelector((state) => state.common.profileToEdit);
  console.log(profileToEdit);
  const handleAddLink = () => {
    if (link) {
      setLinks((prevLinks) => [...prevLinks, link]);
      setLink(""); // Clear the input field
    }
  };

  const handleDeleteLink = (linkToDelete) => {
    setLinks((prevLinks) => prevLinks.filter((l) => l !== linkToDelete));
  };

  var category = window.location.pathname.split("/")[3];
  const result = category.replace(/-/g, " ");

  const handleClickButton = async (e) => {
    e.preventDefault();
    // console.log("workDetails", workDetails);
    // console.log("phoneNumber", phoneNumber);
    // console.log("expectedDate", expectedDate);
    // console.log("links", links[0]);
    var message = `
    Name : ${profileToEdit.companyName}
    Phone : ${phoneNumber}
    Email : ${profileToEdit.loginEmail}
    Expected Deadlie : ${expectedDate}
    Category : ${result}
    About the work : ${workDetails}
    Links : 
    ${links.map((link) => link).join("\n")}
    `;
    const res = await sendMailToDms({
      subject: "Application for DMS/mWorld",
      message: message,
    });
    if (res.status == 200 || res.status == 201) {
      Toaster({ message: "Request successfully sent", variant: "dark" });
    }
    history.push("/dashboard/view");
  };

  return (
    <div className="d-flex customRequestForm w-100">
      {/* Left Side Panel */}
      <div className="left-panel text-white p-4">
        <h1 className="bold text-light">{result}</h1>
        <div className="d-flex align-items-center mt-4 flex-row ">
          <Avatar />
          <div className="ml-4 d-flex flex-column">
            <span className="m-0 title2">{profileToEdit.companyName}</span>
            <span className="description2 opacity-70">
              {profileToEdit.loginEmail
                ? profileToEdit.loginEmail
                : profileToEdit.phoneNumber}
            </span>
          </div>
        </div>
      </div>

      {/* Right Side Form */}
      <form className="right-panel p-8" onSubmit={handleClickButton}>
        <h2 className="bold">About Your Request</h2>
        <div className="overflow-auto d-flex flex-column">
          <div className="mb-2 d-flex flex-column ">
            <span className="title2 mb-4">Brief your work</span>
            <TextField
              placeholder="Brief explanation of project"
              required
              rows="5"
              multiline
              id="brief"
              label="Brief your work"
              variant="outlined"
              value={workDetails}
              onChange={(e) => setWorkDetails(e.target.value)}
            />
          </div>

          <div className="mb-2 d-flex flex-column ">
            <span className="title2 mb-4">Phone Number</span>
            <TextField
              type="number"
              id="brief"
              required
              variant="outlined"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <div className="mb-2 d-flex flex-column w-100">
            <span className="title2 mb-4">Reference</span>
            <div className="d-flex flex-row gap-2 w-100">
              <TextField
                placeholder="Reference link"
                id="brief"
                label="Reference link"
                fullWidth
                variant="outlined"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
              <Button
                variant="contained"
                color="inherit"
                size="medium"
                disableElevation
                onClick={handleAddLink}
              >
                <Add />
              </Button>
            </div>
            <div className="d-flex flex-wrap mt-3 gap-2">
              {links.map((link, index) => (
                <div
                  key={index}
                  className="chiplink rounded-pill py-2 px-4 d-flex flex-row gap-2"
                >
                  {link}

                  <IconButton
                    onClick={() => handleDeleteLink(link)}
                    size="small"
                    color="inherit"
                  >
                    <Close
                      style={{
                        height: "14px",
                        width: "14px",
                      }}
                    />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="mb-2 d-flex flex-column ">
            <span className="title2 mb-4"> Attachment</span>
            <TextField id="brief" type="file" variant="outlined" />
          </div> */}

          <div className="mb-2 d-flex flex-column ">
            <span className="title2 mb-4"> Expected Deadline</span>
            <TextField
              type="date"
              id="brief"
              variant="outlined"
              value={expectedDate}
              onChange={(e) => setExpectedDate(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 right-panel-bottom pb-4 pr-8">
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              // Toaster({ message: "Request Sent", variant: "dark" });
              history.push("/dashboard/view");
            }}
          >
            Back
          </Button>

          <Button variant="contained" color="primary" type="submit">
            Submit Request
          </Button>
        </div>
      </form>
    </div>
  );
}

export default RequestForm;
