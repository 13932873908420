import React from "react";
import "./CustomStyles.css"; // Import custom CSS for styling
import Marquee from "react-fast-marquee";

export default function Customers() {
  var images = [
    "/media/Brands/Aryas hotel.png",
    "/media/Brands/Asset.png",
    "/media/Brands/aster.png",
    "/media/Brands/chittilappilly.png",
    "/media/Brands/dbfs.png",
    "/media/Brands/Aryas.png",
    "/media/Brands/panaso.png",
    "/media/Brands/Pthy.png",
    "/media/Brands/Sapins.png",
    "/media/Brands/wemart.png",
    "/media/Brands/eastea.png",
    "/media/Brands/vW.png"
  ];
  return (
    <div className="container py-5 text-center">
      <div className="image-container d-flex justify-content-between flex-wrap">
        <Marquee autoFill speed={35} pauseOnHover>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              className="customer-logo ml-25"
              alt="Customer logo"
            />
          ))}
        </Marquee>
      </div>
    </div>
  );
}
