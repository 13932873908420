/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { MessageInbox } from "./messageInbox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as actions from "../../../../app/modules/_redux/commonActions";
import { ChatModuleSelector } from "../../../../app/modules/ChatModule/_redux/ChatModuleSelector";
import {
  unreadNotificationCount,
  listNotifications,
  updateUnreadNotificationCount
} from "../extras/dropdowns/notifications/_redux/NotificationActions";
import { getPage } from "./sideBarHelper";
import {
  loadInbox,
  resetInbox
} from "../../../../app/modules/ChatModule/_redux/ChatModuleActions";
import SideBarCampaigns from "./SideBarCampaigns";
import HeaderUserDetails from "../header/header-menu/HeaderUserDetails";
import { MyCampaigns } from "../../../../app/modules/dashboard/components/MyCampaigns";
// import { getPortfolio } from "../../../../app/modules/campaignApplications/_redux/applicationCrud";

export function SideBar({ component, setComponent }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isApiInprogressFlag, user, chatInboxArray } = useSelector(
    ChatModuleSelector,
    shallowEqual
  );
  const {
    userDetails,
    unreadNotificationCounts,
    notificationsList,
    list,
    viewSideBar,
    profileToEdit,
    isVisibleModalInfo,
    portfolioDetails
  } = useSelector(
    (state) => ({
      userDetails: state.auth.user,
      notificationsList: state.notificationModule.notificationsList,
      unreadNotificationCounts:
        state.notificationModule.unreadNotificationCount,
      list: state.notificationModule.list,
      viewSideBar: state.common.isVisibleSideBar,
      profileToEdit: state.common.profileToEdit,
      isVisibleModalInfo: state.common.isVisibleModalInfo,
      portfolioDetails: state.application.portfolioDetails
    }),
    shallowEqual
  );

  const pageSize = 10;
  const page = 1;
  const loggedInUserId = user.userId;
  const fetchMoreInInboxData = () => {
    dispatch(loadInbox(user.userId, page, pageSize, loggedInUserId));
  };

  useEffect(() => {
    dispatch(
      unreadNotificationCount({
        loggedInUserId,
        appInfo: "BRAND"
      })
    );
    location.pathname !== "/messagings/inbox" && fetchMoreInInboxData();
    dispatch(
      actions.getCreatorProfile({
        userId: user?.userId,
        loggedInUserId: user?.userId,
        fields: [
          "profilePic",
          "firstName",
          "lastName",
          "companyName",
          "loginEmail",
          "phoneNumber",
          "gender",
          "age",
          "creatorType",
          "aboutUser",
          "areasOfIneterst",
          "number",
          "phoneCode",
          "instagramFollowerCount",
          "youtubeSubscriptionCount",
          "instagramId",
          "youtubeId",
          "paymentAccountId",
          "primaryModeOfRegistration"
        ]
      })
    );
    // dispatch(
    //   getPortfolio({
    //     page: 1,
    //     pageSize: 6,
    //     loggedInUserId: loggedInUserId,
    //     userId: loggedInUserId,
    //     fileType: "IMAGE",
    //   })
    // );
    return () => {
      dispatch(resetInbox());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for changing the sidebar according to pathname
  useEffect(() => {
    // setComponent(component);
    let campaignUrl = new RegExp("/campaigns/list");
    let viewUrl = new RegExp("/view");
    campaignUrl.test(window.location.href) &&
      viewUrl.test(window.location.href) &&
      setComponent("campaigns");
  }, [location.pathname]);

  return (
    <>
      <div
        className={`sidebar ${
          viewSideBar ? "mob_active " : ""
        } d-flex flex-row-auto flex-column`}
        id="kt_sidebar"
      >
        {/*user details end*/}
        {/*side bar conetnt*/}
        <div class="sidebar-content flex-column-fluid pt-24 px-lg-10 d-flex flex-column align-items-end">
          <MyCampaigns />
        </div>
      </div>
    </>
  );
}
