import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
// import {SubHeader} from "./subheader/SubHeader";
// import {QuickPanel} from "./extras/offcanvas/QuickPanel";
// import {QuickUser} from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import { StickyToolbar } from "./extras/StickyToolbar";
import BrowserNotification, {
  showNotification
} from "./extras/dropdowns/notifications/BrowserNotification";
import * as actions from "./extras/dropdowns/notifications/_redux/NotificationActions";
import { Toaster } from "../../../../src/app/components/Toaster";
import { SideBar } from "./sidebar/sidebar";
import HeaderUserDetails from "./header/header-menu/HeaderUserDetails";

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const location = useLocation();
  const [notificationdata, setNotificationData] = useState({});
  const [component, setComponent] = useState("messageInbox");
  const { loggedInUserId, socket, isRightSidebar } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user.userId,
      socket: state.common.socket,
      isRightSidebar: state.common.isRightSidebar
    }),
    shallowEqual
  );
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended")
    };
  }, [uiService]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    socket?.on(
      "notificationService",
      (notificationMessage) => {
        setNotificationData(notificationMessage);
        dispatch(
          actions.unreadNotificationCount({
            loggedInUserId,
            appInfo: "BRAND"
          })
        );
        let title = notificationMessage.notfyHead;
        let content = notificationMessage.message;
        showNotification(title, content);
      },
      []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  if (
    location?.pathname.startsWith("/messagings/list/") === false &&
    location?.pathname.startsWith("/messagings/inbox") === false
  ) {
    if (
      notificationdata?.idType === "chatmessage" &&
      location?.pathname.startsWith("/messagings/list/") === false &&
      location?.pathname.startsWith("/messagings/inbox") === false
    ) {
      let currentDate = new Date().toLocaleString();
      dispatch(
        actions.sendChatNotification({
          loggedInUserId: loggedInUserId,
          toUser: loggedInUserId,
          fromUser: notificationdata?.id,
          appInfo: "BRAND"
        })
      ).then(() => setNotificationData({}));
    }
  }
  if (
    location?.pathname.startsWith("/messagings/inbox") &&
    notificationdata?.idType === "chatmessage"
  ) {
    Toaster({ message: "new message recieved", variant: "dark" });
  }

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div class={"d-flex flex-column flex-row-fluid"} id="kt_wrapper">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-grow-1 align-items-center">
                <Header setComponent={setComponent} />
              </div>
            </div>

            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {/* {layoutProps.subheaderDisplay && <SubHeader/>} */}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid w-100">
                  {/*begin::Container*/}
                  <div className="w-100 fullwidth">{children}</div>
                  {/*end::Container*/}
                </div>
              )}
              <Footer />

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
          </div>
          {isRightSidebar && (
            <SideBar component={component} setComponent={setComponent} />
          )}
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/* <QuickUser/> */}
      {/* <QuickPanel/> */}
      <ScrollTop />
      <StickyToolbar />
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
