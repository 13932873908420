import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),

      Tabslabel: {
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#44546F"
      },
      h1: {
        fontSize: "32px",
        fontWeight: 500, // medium
        lineHeight: "40px",
        letterSpacing: "-0.5px"
      },
      h2: {
        fontSize: "26px",
        fontWeight: 500, // medium
        lineHeight: "32px",
        letterSpacing: "-0.5px"
      },
      h3: {
        fontSize: "24px",
        fontWeight: 500, // medium
        lineHeight: "32px",
        letterSpacing: "4%"
      },
      h4: {
        fontSize: "22px",
        fontWeight: 500, // medium
        lineHeight: "32px",
        letterSpacing: "0%"
      },
      title1: {
        fontSize: "18px",
        fontWeight: 500, // medium
        lineHeight: "32px",
        letterSpacing: "0%"
      },
      title2: {
        fontSize: "16px",
        fontWeight: 500, // medium
        lineHeight: "24px",
        letterSpacing: "1%"
      },
      subheading: {
        fontSize: "16px",
        fontWeight: 500, // medium
        lineHeight: "24px",
        letterSpacing: "1%",
        color: "#474747",
        opacity: "0.8"
      },
      h1bold: {
        fontSize: "32px",
        fontWeight: 700, // bold
        lineHeight: "40px",
        letterSpacing: "-0.5px",
        color: "#474747"
      },
      h2bold: {
        fontSize: "26px",
        fontWeight: 700, // bold
        lineHeight: "32px",
        letterSpacing: "1%",
        color: "#474747"
      },
      description: {
        fontSize: "14px",
        fontWeight: 300, // light
        lineHeight: "120%",
        letterSpacing: "0.25px"
      },
      description2: {
        fontSize: "12px",
        fontWeight: 300, // light
        lineHeight: "120%",
        letterSpacing: "0.25px"
      },
      label: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 400, // Semibold
        textTransform: "uppercase" // Uppercase
      },

      dropdownHeader: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 600, // Semibold
        textTransform: "uppercase" // Uppercase
      },

      caption1: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 600 // Semibold
      },

      caption2: {
        fontSize: "11px",
        lineHeight: "16px",
        fontWeight: 700 // Bold
      },
      online: {
        fontSize: "11px",
        lineHeight: "16px",
        fontWeight: 700, // Bold
        color: "#36a43e"
      },
      offline: {
        fontSize: "11px",
        lineHeight: "16px",
        fontWeight: 700, // Bold
        color: "#f25555"
      },
      body: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400 // Regular
      },
      termsBody: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 400, // Regular
        opacity: "0.6"
      },
      small: {
        fontSize: "11px",
        lineHeight: "14px",
        fontWeight: 400 // Regular
      },
      link: {
        fontSize: "14px",
        lineHeight: "20px",
        cursor: "pointer",
        fontWeight: 400, // Regular
        color: "#4E627A", // Link color
        textDecoration: "none", // Remove default underline
        transition: "color 0.3s", // Smooth transition for color change
        "&:hover": {
          color: "#313D4D", // Change color on hover
          textDecoration: "underline" // Add underline on hover
        }
      },
      Studioname: {
        fontSize: "20px",
        fontWeight: 400, // medium
        lineHeight: "32px",
        letterSpacing: "4%"
      },
      Setname: {
        fontSize: "20px",
        fontWeight: 300, // medium
        lineHeight: "32px",
        letterSpacing: "4%"
      },
      step: {
        color: "#4e627a",
        fontSize: "14px",
        fontWeight: 800,
        opacity: 0.9,
        marginBottom: "-2px",
        textTransform: "uppercase",
        display: "block"
      }
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#4E627A"
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#D8755F"
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#f018a6"
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
