import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CustomStyles.css"; // Import custom CSS for styling
import { useHistory } from "react-router-dom";

const services = [
  {
    title: "Content Designing",
    image: "/serviceCards/social_media_1x.webp",
    // url: "/template-view/PHOTOGRAPHY",
    url: "/dashboard/request/Content-Designing",
  },
  {
    title: "Influencer Marketing",
    image: "/serviceCards/influencer_marketing_1x.webp",
    // url: "/template-view/SOCIAL_POST",
    url: "/dashboard/request/Influencer-Marketing",
  },
  {
    title: "Celebrity Endorsement",
    image: "/serviceCards/celebrity_endorsement_1x.webp",
    // url: "/template-view/RELATION_POST",
    url: "/dashboard/request/Celebrity-Endorsement",
  },
  {
    title: "Event Sponsorship",
    image: "/serviceCards/visiblity__1x.webp",
    // url: "/template-view/SOCIAL_POST",
    url: "/dashboard/request/Event-Sponsorship",
  },
  {
    title: "Out of Home Advertising",
    image: "/serviceCards/out_of_door_servie_1x.webp",
    // url: "/template-view/SOCIAL_POST",
    url: "/dashboard/request/Out-of-Home-Advertising",
  },
  {
    title: "Build Customer Loyalty",
    image: "/serviceCards/customer_loyalty_1x.webp",
    // url: "/template-view/RELATION_POST",
    url: "/dashboard/request/Build-Customer-Loyalty",
  },
];

const ServiceGrid = () => {
  const history = useHistory();
  const handleClickCard = (service) => {
    history.push(service?.url);
  };
  return (
    <div className="container py-5" id="services">
      <div className="d-flex flex-column py-7">
        <h1 class="bold">Marketing Services</h1>
        <p className="description opacity-70">
          Choose the marketing services your business needs today!
        </p>
      </div>
      <div className="row">
        {/* First row with 4 elements */}
        {services.slice(0, 4).map((service, index) => (
          <div
            key={index}
            className="col-md-3 mb-8"
            onClick={() => handleClickCard(service)}
          >
            <div
              className="custom-card"
              style={{
                backgroundImage: `url(${service.image})`,
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              <div className="serviceCardOverlay"></div>

              <div className="serviceCardBody d-flex flex-column justify-content-end align-items-start p-6">
                <h5 className="card-title mb-4">{service.title}</h5>
                <button className="custom-btn mt-auto">
                  Request{" "}
                  <img
                    src="/serviceCards/btnIcon.svg"
                    style={{ width: "22px" }}
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row">
        {/* Second row with 2 elements */}
        {services.slice(4, 6).map((service, index) => (
          <div
            key={index}
            className="col-md-6 mb-4"
            onClick={() => handleClickCard(service)}
          >
            <div
              className="custom-card"
              style={{
                backgroundImage: `url(${service.image})`,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className="serviceCardOverlay"></div>

              <div className="serviceCardBody d-flex flex-column justify-content-end align-items-start p-6">
                <h5 className="card-title mb-4">{service.title}</h5>
                <button className="custom-btn mt-auto">
                  Request{" "}
                  <img
                    src="/serviceCards/btnIcon.svg"
                    style={{ width: "22px" }}
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceGrid;
