import axios from "axios";

export const GET_APPLICATIONS = "v1/brand/campaign/applications/list";
export const ADD_RATING = "v1/creator/rating/review/addRating";
export const GET_RATING = "v1/creator/rating/review/getPreviousCreatorRating";
export const GET_CREATOR_RATING = "v1/creator/rating/review/getRating";
export const ADD_CREATOR_RATING = "v1/creator/rating/review/addCreatorRating";
export const GET_APPLICATION_CONTENTS =
  "v1/brand/campaign/application/contents/list";
// export const CREATE_APPLICATION_CONTENTS =
//   "v1/creator/campaign/application/contents/create";
// export const UPDATE_APPLICATION_CONTENTS =
//   "v1/creator/campaign/application/contents/update";
// export const GENERATE_MEDIA_FILE_URL =
//   "v1/brand/campaign/application/contents/generateMediaDownloadUrl";
export const APPROVE_APPLICATION = "v1/brand/campaign/applications/approve";
export const REJECT_APPLICATION = "v1/brand/campaign/applications/reject";
export const APPROVE_APPLICATION_CONTENT =
  "v1/brand/campaign/application/contents/approve";
export const REJECT_APPLICATION_CONTENT =
  "v1/brand/campaign/application/contents/reject";
export const CREATE_PAYMENT_INTENT = "v1/payments/createPaymentIntent";
export const GET_DOWNLOAD_MEDIA_URL =
  "v1/brand/campaign/application/contents/generateMediaDownloadUrl";
export const UPDATE_TRACKING_NUMBER =
  "v1/brand/campaign/applications/updateTrackingNumber";
export const GET_TRACKING_DETAILS = "v1/shipment/tracking/getTrackingResults";
export const GET_CAMPAIGN_DETAILS = "v1/brand/campaigns/details";
export const LIST_PORTFOLIO = "v1/creator/portfolio/listPortfolioContents";
export const GET_COURIER_LIST = "v1/shipment/tracking/getCouriersList";
export const RESUBMIT_APPLICATION_CONTENT =
  "v1/brand/campaign/application/contents/resubmit";
export const REQUEST_SOCIAL_URL =
  "v1/brand/campaign/application/contents/requestSocialUrl";
export const CONFIRM_CAMPAIGN_PAYMENT = "v2/paymentsV2/createPaymentIntent";

export function getCampaignDetails(params) {
  return axios.post(GET_CAMPAIGN_DETAILS, {
    ...params,
    frontendAction: "creator.campaigns.details",
    trackingPriority: 0,
  });
}

export function getApplications(params) {
  return axios.post(GET_APPLICATIONS, {
    ...params,
    frontendAction: "brand.campaign.applications.list",
    trackingPriority: 0,
  });
}

export function addRating(params) {
  return axios.post(ADD_RATING, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.rating.review.addRating",
  });
}

export function addCreatorRating(params) {
  return axios.post(ADD_CREATOR_RATING, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.rating.review.addCreatorRating",
  });
}

export function getRating(params) {
  return axios.post(GET_RATING, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.rating.review.getPreviousCreatorRating",
  });
}

export function getApplicationContents(params) {
  return axios.post(GET_APPLICATION_CONTENTS, {
    ...params,
    frontendAction: "brand.campaign.application.contents.list",
    trackingPriority: 0,
  });
}

// export function createApplicationContents(params) {
//   return axios.post(CREATE_APPLICATION_CONTENTS, {
//     ...params,
//     frontendAction: "creator.campaign.application.contents.create",
//     trackingPriority: 0,
//   });
// }

// export function updateApplicationContents(params) {
//   return axios.post(UPDATE_APPLICATION_CONTENTS, {
//     ...params,
//     frontendAction: "creator.campaign.application.contents.update",
//     trackingPriority: 0,
//   });
// }

// export function generateMediaUploadUrl(params) {
//   return axios.post(GENERATE_MEDIA_FILE_URL, {
//     ...params,
//     frontendAction:
//       "brand.campaign.application.contents.generateMediaDownloadUrl",
//     trackingPriority: 0,
//   });
// }

// export function signedUpload(url, file) {
//   console.log("File from crud", file);
//   let config = {
//     headers: {
//       "Content-type": file.type,
//     },
//   };
//   return axios.put(url, file, config);
// }

// export function confirmCampaignPayment(params) {
//   return axios.post(CONFIRM_CAMPAIGN_PAYMENT, {
//     ...params,
//     frontendAction: "payments.capturePayment",
//     trackingPriority: 0,
//   });
// }

export function confirmCampaignPayment(params) {
  return axios.post(CONFIRM_CAMPAIGN_PAYMENT, {
    ...params,
    frontendAction: "paymentsV2.createPaymentIntent",
    trackingPriority: 0,
  });
}

export function requestSocialPostContentUrl(params) {
  return axios.post(REQUEST_SOCIAL_URL, {
    ...params,
    frontendAction:
      "brand.campaign.application.contents.setRequestForSocailMediaUrl",
    trackingPriority: 0,
  });
}

export function approveApplication(params) {
  return axios.post(APPROVE_APPLICATION, {
    ...params,
    frontendAction: "brand.campaign.applications.approve",
    trackingPriority: 0,
  });
}

export function rejectApplication(params) {
  return axios.post(REJECT_APPLICATION, {
    ...params,
    frontendAction: "brand.campaign.applications.reject",
    trackingPriority: 0,
  });
}

export function resubmitApplicationContent(params) {
  return axios.post(RESUBMIT_APPLICATION_CONTENT, {
    ...params,
    frontendAction: "brand.campaign.application.contents.resubmit",
    trackingPriority: 0,
  });
}

export function approveApplicationContent(params) {
  return axios.post(APPROVE_APPLICATION_CONTENT, {
    ...params,
    frontendAction: "brand.campaign.application.contents.approve",
    trackingPriority: 0,
  });
}

export function rejectApplicationContent(params) {
  return axios.post(REJECT_APPLICATION_CONTENT, {
    ...params,
    frontendAction: "brand.campaign.application.contents.reject",
    trackingPriority: 0,
  });
}

export function createPaymentIntent(params) {
  return axios.post(CREATE_PAYMENT_INTENT, {
    ...params,
    frontendAction: "payments.createPaymentIntent",
    trackingPriority: 0,
  });
}

export function getMediaDownloadURL(params) {
  return axios.post(GET_DOWNLOAD_MEDIA_URL, {
    ...params,
    frontendAction:
      "brand.campaign.application.contents.generateMediaDownloadUrl",
    trackingPriority: 0,
  });
}

export function getMediaViewURL(params) {
  return axios.post(GET_DOWNLOAD_MEDIA_URL, {
    ...params,
    frontendAction:
      "brand.campaign.application.contents.generateMediaDownloadUrl",
    trackingPriority: 0,
  });
}

export function updateShipment(params) {
  return axios.post(UPDATE_TRACKING_NUMBER, {
    ...params,
    frontendAction: "brand.campaign.applications.updateTrackingNumber",
    trackingPriority: 0,
  });
}

export function getTrackingDetails(params) {
  return axios.get(GET_TRACKING_DETAILS, {
    params,
  });
}

export function getPortfolio(params) {
  return axios.post(LIST_PORTFOLIO, {
    ...params,
    frontendAction: "brand.portfolio.listPortfolioContents",
    trackingPriority: 0,
  });
}

export async function getCourierList() {
  return axios.get(GET_COURIER_LIST);
}

export function getCreatorRating(params) {
  return axios.post(GET_CREATOR_RATING, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.rating.review.getRating",
  });
}

export async function sendMailToDms(params) {
  try {
    var res = await axios.post(
      process.env.REACT_APP_SUB_API_URL + "common/send-mail",
      params
    );
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
}
