import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { ChooseApplicationType } from "./pages/ChooseApplicationType";
import { createSocket } from "./modules/_redux/commonActions";
import  DashBoardPage  from "./modules/dashboard/DashBoardPage";

const SocialPostApplicationV2 = lazy(() =>
  import("./modules/SocialPostApplicationV2/SocialPostApplicationV2")
);
const templateView = lazy(() =>
  import("./modules/dashboard/components/templateView")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const CampaignsPage = lazy(() => import("./modules/campaignDetails/campaignsPage"));
// const NonActiveCampaignsPage = lazy(() => import("./modules/campaign/non-active-campaign-list/NonActiveCampaignPage"));

const ApplicationsPage = lazy(() =>
  import("./modules/campaignApplications/applicationsPage")
);
const ChatModule = lazy(() => import("./modules/ChatModule/ChatModule"));

const GalleryPage = lazy(() => import("./modules/gallery/galleryPage"));

const PostAnalyticsPage = lazy(() => import("./modules/Post-Analytics/PostAnalyticsPage"));

const PaymentHistory = lazy(() => import("./modules/PaymentHistory/PaymentHistoryPage"));

const ActiveCampaign = lazy(() => import("./modules/CampaignV2/ActiveCampaign/ActiveCampaignPage"));
const PendingCampaign = lazy(() => import("./modules/CampaignV2/NonActiveCampaign/NonActiveCampaignPage"));
const CompletedCampaign = lazy(() => import("./modules/CampaignV2/completedCampaign/CompletedCampaignPage"));

const applicationList = lazy(()=> import ("./modules/CampaignApplicationsV2/applicationPage"));

export default function BasePage() {
  const dispatch = useDispatch();
  var socket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
    transports: ["websocket"],
  });

  const { userId } = useSelector(
    (state) => ({
      userId: state?.auth?.user?.userId,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(createSocket(socket));
    if (userId) {
      socket.connect();
      socket.emit("create-room", userId+"_chat");
      socket.emit("joinRoom", userId+"_chat", socket.id);
      socket.emit("create-room", userId);
      socket.emit("joinRoom", userId, socket.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashBoardPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/social-post-v2/:templateId/template-social-post-v2" component={SocialPostApplicationV2} />
        <Route path="/social-post-v2/:campaignId/edit-social-post-v2" component={SocialPostApplicationV2} />
        <Route path="/social-post-v2" component={SocialPostApplicationV2} />
        <Route path="/template-view/:campaignType" component={templateView} />
        <Route path="/select-application" component={ChooseApplicationType} />
        <Route path="/messagings" component={ChatModule} />
        <Route path="/campaigns" component={CampaignsPage} />
        <Route path="/activeCampaign" component={ActiveCampaign} />
        <Route path="/pendingCampaign" component={PendingCampaign} />
        <Route path="/completedCampaign" component={CompletedCampaign} />
        {/* <Route path="/campaigns/non-active-list" component={NonActiveCampaignsPage} /> */}
        <Route path="/applications" component={ApplicationsPage} />
        {/* <Route path="/applications" component={applicationList} /> */}
        <Route path="/gallery" component={GalleryPage} />
        <Route path="/post-analytics" component={PostAnalyticsPage} />
        <Route path="/PaymentHistory" component={PaymentHistory} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
