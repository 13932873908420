import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { AuthPage } from "./AuthPage";
import React from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";

export function AuthRootPage() {
  const { webLoading } = useSelector(
    (state) => ({
      webLoading: state.common.webLoading,
    }),
    shallowEqual
  );

  return (
    <>
      {webLoading === true && <LayoutSplashScreen />}
      <div classname="" id="main_page_design">
        <div class="navbar navbar navbar-expand-md d-flex justify-content-between w-100">
          <div class="d-flex justify-content-between bd-highlight py-2">
            <a class="navbar-brand col d-flex align-items-end" href="/">
              <img
                height="30px"
                class="logo_dark"
                src="/media/logo/white.svg"
              />
            </a>
          </div>
        </div>
        <section
          id="home"
          class="navbar-is-white text-white pp-scrollable d-flex align-items-center section position-absolute min-vh-100"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/design_home/bg_brand.webp"
            )})`,
          }}
        >
          <div class="intro">
            <div class="scroll-wrap">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 col-xl-8 pt-4 d-flex align-items-center ">
                    <div class="col-md-12 my-lg-10">
                      {/* <img class="w-100 banner_image" src="/media/design_home/brand_image.png" /> */}
                      <h3 class="text-white mt-0 mb-2 mt-5  pt-4 h2">
                        <span class="text-primary text-typed a-typed a-typed-brand">
                          Smart Marketing With mWorld
                        </span>
                      </h3>
                      {/* <h3 class="text-white mt-0 mb-2 mt-5 banner_text_top heading pt-4 h2"><span class="text-primary text-typed a-typed a-typed-brand">Marketplace that can <span class="text-secondary ">power your exponential growth</span></span></h3> */}
                      <div class="mb-10 text-line sub_text mt-3 mr-12 opacity-80">
                        A marketplace designed for every businesses to discover,
                        collaborate, and achieve their marketing goals. The
                        platform offers services such as celebrity endorsements,
                        build visibility strategies, and out-of-home (OOH)
                        advertising
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4 d-flex align-items-center">
                    <div class="position-relative login_details d-flex flex-wrap d-flex justify-content-center align-items-center devman_tm_hero my-10 my-15">
                      <AuthPage />
                      <div class="mt-5 row mx-0 col-md-12 align-items-center d-flex justify-content-center d-flex text-center d-none">
                        <p class="banner_text d-none">
                          No software fees <span>|</span> No subscription{" "}
                          <span>|</span> No credit card required
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="brand"
          class="section pp-scrollable d-flex align-items-center position-absolute active"
        >
          <div class="intro d-none">
            <div class="scroll-wrap">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-5 pr-md-5 pr-lg-0">
                    <div class="mb-1 text-dark opacity-80">Brands</div>
                    <h2 class="text-dark mb-2">
                      Create stories which inspires your customers
                    </h2>
                    <p class="text-line sub_text opacity-60">
                      Inspire your customer with creative stories | Collaborate
                      with best creators and influencers | Grow brand lovers |
                      Achieve exponential reach and sales.
                    </p>

                    <div class="mt-4">
                      <a href="#home" class="btn btn_secondary">
                        Sign up
                      </a>
                      <a href="#home" class="btn ml-3">
                        Discover
                      </a>
                    </div>
                    <div class="mt-5">
                      <h6 class="mb-3">Top creators</h6>
                      <div class="row creator">
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Abhirami.jpg"
                          />
                          <p class="creator_list">Abhirami Vishnu</p>
                        </div>
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Sachin.jpg"
                          />
                          <p class="creator_list">Sachin shanavas</p>
                        </div>
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Aneesh.jpg"
                          />
                          <p class="creator_list">Aneesh S Nair</p>
                        </div>
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Shikha.jpg"
                          />
                          <p class="creator_list">Aryan D Nair </p>
                        </div>
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Aryan.jpg"
                          />
                          <p class="creator_list">Dr. Shikha Santhosh</p>
                        </div>
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Jamshi.jpg"
                          />
                          <p class="creator_list">Jamshi </p>
                        </div>
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Suchi.jpg"
                          />
                          <p class="creator_list">Suchi</p>
                        </div>
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Foodie.jpg"
                          />
                          <p class="creator_list">Foodie Sha</p>
                        </div>
                        <div class=" col-sm-6 col-md-4 col-xl-2  col-4">
                          <img
                            alt=""
                            class="w-100"
                            src="/media/design_home/influencers/Maria.jpg"
                          />
                          <p class="creator_list">Maria Dominic</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-1"></div>
                  <div class="col-lg-6">
                    <div class="position-relative">
                      <div class="photo-icon photo-icon-1">
                        <img
                          alt=""
                          class="w-100"
                          src="/media/design_home/component1.png"
                        />
                      </div>
                      <div class="photo-icon photo-icon-2">
                        <img
                          alt=""
                          class="w-100"
                          src="/media/design_home/component2.png"
                        />
                      </div>
                      <div class="photo-icon photo-icon-3">
                        <img
                          alt=""
                          class="w-100"
                          src="/media/design_home/component3.png"
                        />
                      </div>
                      <div class="photo-icon photo-icon-4">
                        <img
                          alt=""
                          class="w-100"
                          src="/media/design_home/component4.png"
                        />
                      </div>
                      <div class="photo-icon photo-icon-5">
                        <img
                          alt=""
                          class="w-100"
                          src="/media/design_home/component5.png"
                        />
                      </div>
                      <img
                        alt=""
                        class="border-radius w-100"
                        src="/media/design_home/brand_home.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="Services"
          class="section pp-scrollable position-absolute"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/design_home/service_bg.png"
            )})`,
          }}
        >
          <div class="intro d-none">
            <div class="scroll-wrap">
              <div class="container">
                <h2 class="mb-0">
                  Our<span class="text-white"> Services</span>{" "}
                </h2>
                <div class="mt-0 pt-2">
                  <div class="row-partners row align-items-center d-flex align-items-stretch ">
                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/content.png"
                        />
                        <p class="h5 small">
                          Personalized & Tailored Content Generation
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/marketing.png"
                        />
                        <p class="h5">Creative Influencer Marketing</p>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center new_service service_item">
                        <span class="premium d-none">
                          <img
                            class="h-100"
                            src="/media/design_home/premium_3.png"
                          />
                        </span>
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/Shop.png"
                        />
                        <p class="h5">
                          Boost Shop
                          <br />
                          Walk-Ins
                        </p>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/Consultation_2.png"
                        />
                        <p class="h5">
                          Marketing <br />
                          Consultation
                        </p>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center coming_soon service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/community.png"
                        />
                        <p class="h5">Build Brand Personalised Community</p>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center coming_soon service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/Discovery.png"
                        />
                        <p class="h5">
                          Fastest Creator <br /> Discovery
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center coming_soon service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/Consultation.png"
                        />
                        <p class="h5">
                          B2B Service
                          <br /> Catalog
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/Advertising2.png"
                        />
                        <p class="h5">
                          Outdoor <br /> Advertising
                        </p>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center coming_soon service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/email.png"
                        />
                        <p class="h5">Email Marketing</p>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center coming_soon service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/Bot_Marketing.png"
                        />
                        <p class="h5">
                          Bot <br /> Marketing
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center coming_soon service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/Metaverse.png"
                        />
                        <p class="h5">Metaverse Marketing</p>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2 d-flex align-items-stretch  col-6">
                      <div class="w-100 text-center d-flex align-content-between flex-wrap justify-content-center coming_soon service_item">
                        <img
                          class="max-w-90px mt-4"
                          src="/media/design_home/event.png"
                        />
                        <p class="h5">Event Marketing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="experience"
          class="navbar-is-white text-white section pp-scrollable d-flex align-items-center position-absolute"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/design_home/bg/experience.jpg"
            )})`,
          }}
        >
          <div class="intro d-none">
            <div class="scroll-wrap">
              <div class="container">
                <h2 class="text-white mb-0"> How it works</h2>
                <div class="mt-5 pt-0">
                  <div class="carousel-experience owl-carousel">
                    <div class="experience-item">
                      <div class="row row-experience">
                        <div class="col-md-2">
                          <a href="">
                            <img
                              alt=""
                              height="80"
                              src="/media/design_home/describe.png"
                            />
                          </a>
                        </div>
                        <div class="col-md-4 my-0">
                          Describe{" "}
                          <h3 class="my-0 text-white">Your Requirement</h3>
                        </div>
                        <div class="col-md-6">
                          We offer various campaign creation tools to present
                          your requirements and meet your marketing goals.
                        </div>
                      </div>
                      <div class="row row-experience">
                        <div class="col-md-2">
                          <a href="">
                            <img
                              alt=""
                              height="80"
                              src="/media/design_home/Applications.png"
                            />
                          </a>
                        </div>
                        <div class="col-md-4 my-0">
                          Get<h3 class="my-0 text-white"> Applications</h3>
                        </div>
                        <div class="col-md-6">
                          The best creators and influencers from the 1000X
                          community will reach out to you with campaign
                          applications.
                        </div>
                      </div>
                      <div class="row row-experience">
                        <div class="col-md-2">
                          <a href="">
                            <img
                              alt=""
                              height="80"
                              src="/media/design_home/Reward.png"
                            />
                          </a>
                        </div>
                        <div class="col-md-4">
                          Review and{" "}
                          <h3 class="my-0 text-white"> Reward The Work</h3>
                        </div>
                        <div class="col-md-6">
                          Review the work done by creators and influencers and
                          reward them.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="partners" class="section pp-scrollable position-absolute">
          <div class="intro d-none">
            <div class="scroll-wrap">
              <div class="container">
                <h2 class="mb-0">
                  <span class="text-primary">Our</span> Partners
                </h2>
                <div class="mt-0 pt-2">
                  <div class="row-partners row align-items-center ">
                    <div class="col-partner creator  col-sm-6 col-md-4 col-xl-2  col-4">
                      <img alt="" src="/media/design_home/partners/1.png" />
                    </div>

                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img alt="" src="/media/design_home/partners/3.png" />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img alt="" src="/media/design_home/partners/4.png" />
                    </div>

                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img alt="" src="/media/design_home/partners/7.png" />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img alt="" src="/media/design_home/partners/9.png" />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img alt="" src="/media/design_home/partners/11.png" />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img alt="" src="/media/design_home/partners/10.png" />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img
                        alt=""
                        src="/media/design_home/partners/crust_crumb.png"
                      />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img alt="" src="/media/design_home/partners/beam.png" />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img
                        alt=""
                        src="/media/design_home/partners/fortuler.png"
                      />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img
                        alt=""
                        src="/media/design_home/partners/underdone.png"
                      />
                    </div>
                    <div class="col-partner col-sm-6 col-md-4 creator col-xl-2  col-4">
                      <img
                        alt=""
                        src="/media/design_home/partners/KaramatKaramat.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="projects"
          class="navbar-is-white text-white section pp-scrollable position-absolute"
        >
          <div class="project-wrap d-none">
            <div class="bg-changer">
              <div
                class="section-bg active"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/design_home/bg/portfolio/bg1.png"
                  )})`,
                }}
              ></div>
            </div>
            <div class="intro">
              <div class="scroll-wrap">
                <div class="container">
                  <h2 class="text-white mb-0">Latest Campaigns</h2>
                  <div class="mt-5 pt-2">
                    <div class="row-project-box row">
                      <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                        <a
                          href=""
                          class="project-box"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/design_home/bg/campagin/1.png"
                            )})`,
                          }}
                        >
                          <div class="project-box-inner">
                            <h4>Chickly Influencer Campaign</h4>
                            <div class="project-category">
                              Influencer Campaign
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                        <a
                          href=""
                          class="project-box"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/design_home/bg/campagin/2.png"
                            )})`,
                          }}
                        >
                          <div class="project-box-inner">
                            <h4>Global distribution of startups</h4>
                            <div class="project-category">
                              Social media post
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                        <a
                          href=""
                          class="project-box"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/design_home/bg/campagin/3.png"
                            )})`,
                          }}
                        >
                          <div class="project-box-inner">
                            <h4>Goldsach SM ad</h4>
                            <div class="project-category">
                              Social Media post
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                        <a
                          href=""
                          class="project-box"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/design_home/bg/campagin/4.png"
                            )})`,
                          }}
                        >
                          <div class="project-box-inner">
                            <h4>IIA Promotion</h4>
                            <div class="project-category">Graphic design</div>
                          </div>
                        </a>
                      </div>
                      <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                        <a
                          href=""
                          class="project-box"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/design_home/bg/campagin/5.png"
                            )})`,
                          }}
                        >
                          <div class="project-box-inner">
                            <h4>Chickly social media ad</h4>
                            <div class="project-category">
                              Social Media post
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="col-project-box col-md-6 col-lg-4 col-xl-3">
                        <a
                          href=""
                          class="project-box"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/design_home/bg/campagin/6.png"
                            )})`,
                          }}
                        >
                          <div class="project-box-inner">
                            <h4> diReign Ads</h4>
                            <div class="project-category">Graphic design</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="contact"
          class="navbar-is-white text-white section pp-scrollable position-absolute"
        >
          <div class="intro d-none">
            <div class="scroll-wrap">
              <div class="container">
                <div class="row mb-5">
                  <div class="col-md-4 text-dark">
                    <h2 class="text-dark">Contact</h2>
                    <h4 class="mt-5 pt-5  text-secondary">
                      Thox Technologies Pvt Ltd{" "}
                    </h4>
                    <h5 class="text-dark">(+91) 9526 151 000</h5>
                    <a
                      href="mailto:support@1000x.family"
                      class="mt-3  text-dark"
                    >
                      support@1000x.family
                    </a>
                  </div>
                  <div class="col-md-5 offset-md-2">
                    <div class="mt-5">
                      <form class="js-ajax-form">
                        <div class="form-group">
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            placeholder="Name"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            type="email"
                            name="email"
                            class="form-control"
                            required=""
                            placeholder="Email *"
                          />
                        </div>
                        <div class="form-group">
                          <textarea
                            rows="3"
                            name="message"
                            class="form-control"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div class="message" id="success-message">
                          Your message is successfully sent...
                        </div>
                        <div class="message" id="error-message">
                          Sorry something went wrong
                        </div>
                        <div class="form-group mb-0">
                          <button type="submit" class="btn">
                            Contact me
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="navbar-bottom">
            <div class="container">
              <div class="social">
                <ul class="social-icons mr-auto mr-lg-0 d-none d-sm-block">
                  <li>
                    <a href="https://www.facebook.com/1000xSocial">
                      <i class="text-dark" name="logo-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/1000x.club/">
                      <i class="text-dark" name="logo-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/1000xclub/">
                      <i class="text-dark" name="logo-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="copy d-none text-dark d-sm-block mr-5 pr-4">
                <p class="text-dark">
                  © 2024-2025 All rights reserved. <strong>DMSG Pvt Ltd</strong>{" "}
                  is a registered trademark
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
