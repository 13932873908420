export const requests = [
  {
    requestId: "REQ001",
    requesterName: "Alice Thompson",
    projectType: "Website Design",
    description:
      "Create a responsive portfolio website for showcasing photography.",
    dateRequested: "2024-11-01",
    status: "approved",
    creatorAssigned: "Designer_Alex",
    dueDate: "2024-11-20",
    notes: "Client prefers a minimalistic theme."
  },
  {
    requestId: "REQ002",
    requesterName: "Bob Lewis",
    projectType: "Mobile App Development",
    description: "Develop a fitness tracking app with social sharing features.",
    dateRequested: "2024-11-03",
    status: "pending",
    creatorAssigned: null,
    dueDate: "2024-12-10",
    notes: "High priority for fitness category."
  },
  {
    requestId: "REQ003",
    requesterName: "Samantha Green",
    projectType: "Social Media Content Creation",
    description: "Design weekly social media posts for brand engagement.",
    dateRequested: "2024-11-05",
    status: "approved",
    creatorAssigned: "ContentCreator_James",
    dueDate: "2024-11-30",
    notes: "Focus on Instagram and Facebook."
  },
  {
    requestId: "REQ004",
    requesterName: "David Kim",
    projectType: "Logo Design",
    description:
      "Create a modern, simple logo for an eco-friendly product line.",
    dateRequested: "2024-11-06",
    status: "pending",
    creatorAssigned: null,
    dueDate: "2024-11-25",
    notes: "Use green and blue tones."
  }
];

export const approvedRequests = requests.filter(
  (request) => request.status === "approved"
);
export const pendingRequests = requests.filter(
  (request) => request.status === "pending"
);
