import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
// import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
// import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { headerSlice } from "../_metronic/layout/components/header/_redux/HeaderSlice";
import { snackbarSlice } from "./slices/snackbar/snackbarSlice";
import { userAccessSlice } from "../app/modules/UserAccess/_redux/userAccessSlice";
import { applicationSlice } from "../app/modules/campaignApplications/_redux/applicationSlice";
import { ChatModuleSlice } from "../app/modules/ChatModule/_redux/ChatModuleSlice";
import { gallerySlice } from "../app/modules/gallery/_redux/gallerySlice";
import { commonSlice } from "../app/modules/_redux/commonSlice";
import { campaignSlice } from "../app/modules/campaign/_redux/campaignSlice";
import { postAnalyticsSlice } from "../app/modules/Post-Analytics/_redux/postAnalyticsSlice";
import { SocialPostV2ApplicationSlice } from "../app/modules/SocialPostApplicationV2/_redux/SocialPostApplicationSliceV2";
import {paymentHistorySlice} from "../app/modules/PaymentHistory/_redux/PaymentHistorySlice"
import { notificationSlice } from "../_metronic/layout/components/extras/dropdowns/notifications/_redux/NotificationSlice";
import { brandInviteeSlice } from "../app/modules/UserProfile/brand-send-invitee-dialog/_redux/brandInviteSlice";
import { UpdateSocialPostV2ApplicationSlice } from "../app/modules/UpdateSocialPostApplicationV2/_redux/SocialPostApplicationSliceV2";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  // customers: customersSlice.reducer,
  header: headerSlice.reducer,
  // products: productsSlice.reducer,
  userAccess: userAccessSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer,
  snackbar: snackbarSlice.reducer,
  socialPostV2Application: SocialPostV2ApplicationSlice.reducer,
  application: applicationSlice.reducer,
  chatModule: ChatModuleSlice.reducer,
  gallery: gallerySlice.reducer,
  common: commonSlice.reducer,
  campaign: campaignSlice.reducer,
  postAnalytics: postAnalyticsSlice.reducer,
  paymentHistory:paymentHistorySlice.reducer,
  notificationModule:notificationSlice.reducer,
  brandInvitation: brandInviteeSlice.reducer,
  updateSocialPostV2Application:UpdateSocialPostV2ApplicationSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
