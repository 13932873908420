import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../campaign/_redux/campaignActions";
import { useHistory } from "react-router-dom";
import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Tab,
  Tabs
} from "@material-ui/core";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { Close } from "@material-ui/icons";
import { setRightSideBar } from "../../_redux/commonActions";
import { a11yProps } from "./Customtab/elements";
import { CustomTabPanel } from "./Customtab/CustomTabPanel";
import {
  approvedRequests,
  pendingRequests,
  requests
} from "./dummydata/dummydata";
import { RequestCard } from "./RequestCard/RequestCard";

const useStyles = makeStyles({
  root: {},
  media: {}
});

export function MyCampaigns() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);

  const [pending, setPending] = useState(false);
  const [active, setActive] = useState(true);

  const [complete, setComplete] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [activeTab, setActiveTab] = useState("pending");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const { currentState, loggedInUserId, listLoading } = useSelector(
    (state) => ({
      currentState: state.campaign,
      loggedInUserId: state.auth.user.userId,
      listLoading: state.campaign.listLoading
    }),
    shallowEqual
  );

  const { totalCount, entities, totalPages, completedCampaigns } = currentState;

  // const expiryDate = new Date().toISOString();
  var date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  let expiryDate = date;

  useEffect(() => {
    complete
      ? dispatch(
          actions.listCompletedCampaigns({
            frontendAction: "creator.campaigns.list",
            loggedInUserId: loggedInUserId,
            page: currentPage,
            pageSize: 3,
            sort: "-updatedAt",
            sortField: "id",
            sortOrder: "asc",
            trackingPriority: 0,
            expiryFilter: expiryDate,
            query: {
              brandId: loggedInUserId
              // campaignStatus: "WAITING_FOR_APPROVAL_BY_ADMIN"
              // campaignStatus: "WAITING_FOR_APPROVAL_BY_ADMIN",
            }
          })
        )
      : // eslint-disable-next-line react-hooks/exhaustive-deps
        // server call by queryParams
        dispatch(
          actions.listCampaigns({
            frontendAction: "creator.campaigns.list",
            loggedInUserId: loggedInUserId,
            page: currentPage,
            pageSize: 3,
            sort: "-updatedAt",
            sortField: "id",
            sortOrder: "asc",
            trackingPriority: 0,
            expiryFilter: expiryDate,
            query: {
              brandId: loggedInUserId,
              campaignStatus: active
                ? ["APPROVED_BY_ADMIN", "CAMPAIGN_APPROVED_IN_RESTRICTED_MODE"]
                : ["WAITING_FOR_APPROVAL_BY_ADMIN"]
              // campaignStatus: "WAITING_FOR_APPROVAL_BY_ADMIN",
            }
          })
        );
  }, [currentPage, active]);

  const DateFormatter = (cellContent) => {
    let formattedDate = "";
    if (cellContent) {
      let date = parseISO(cellContent);
      formattedDate = format(date, "dd MMM yyyy").toString();
    }
    return <span>{formattedDate}</span>;
  };

  useEffect(() => {
    if (complete) {
      setCampaignList(completedCampaigns);
    } else {
      setCampaignList(entities);
    }
  }, [complete, completedCampaigns, entities]);

  return (
    <div
      class="card card-custom gutter-b h-100 w-25 shadow"
      style={{ maxHeight: "80vh", overflow: "hidden" }}
    >
      <div class="card-header border-0 pt-0 px-4 mb-0 h-75">
        <div class="mr-0 my-0 py-5 d-flex flex-row justify-content-between align-items-center w-100">
          <h3
            class="bold"
            // onClick={() => {
            //   history.push("/activeCampaign");
            // }}
          >
            My Requests
          </h3>
          <IconButton onClick={() => dispatch(setRightSideBar(false))}>
            <Close />
          </IconButton>
        </div>
      </div>

      {/* <div className="w-100 d-flex justify-content-between">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Status Tabs"
            centered
            className="w-100"
          >
            <Tab
              label="Pending"
              value="pending"
              onClick={() => {
                setActiveTab("pending");
              }}
            />
            <Tab
              label="Active"
              value="active"
              onClick={() => {
                setActiveTab("active");
              }}
            />
          </Tabs>
        </div> */}

      <Box style={{ width: "100%", height: "60vh" }}>
        <Box style={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="request category switch "
          >
            <Tab label="Pending" {...a11yProps(0)} />
            <Tab label="Approved" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="px-4 w-100 overflow-auto" style={{ height: "75vh" }}>
            <div className=" w-100 h-auto d-flex gap-2 flex-column pb-30 pt-4">
              {pendingRequests.map((request) => (
                <RequestCard request={request} />
              ))}
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="px-4 w-100 overflow-auto" style={{ height: "75vh" }}>
            <div className=" w-100 h-auto d-flex gap-2 flex-column pb-30 pt-4">
              {approvedRequests.map((request) => (
                <RequestCard request={request} approved />
              ))}
            </div>
          </div>
        </CustomTabPanel>
      </Box>
      <div className="p-4 d-flex justify-content-center align-items-center">
        <Link
          style={{
            cursor: "pointer"
          }}
        >
          Show more
        </Link>
      </div>

      {/* 
      {campaignList?.length > 0 ? (
        <div class="d-column row px-5">
          {campaignList?.map((item, index) => (
            <div class="col-12 " key={index}>
              <div
                class="d_card w-100 d-flex align-content-between flex-wrap"
                style={{
                  backgroundImage: item?.productImageUrl
                    ? `url(${process.env.REACT_APP_AWS_S3_BASE_URL +
                        item?.productImageUrl})`
                    : "url(/media/dummyImages/no_preview.png"
                }}
              >
                <div class="col-12 px-4 d-flex justify-content-end">
                  <span class="label label-outline-success label-pill label-inline label_dark">
                    {item?.campaignCategory}
                  </span>
                  {pending && (
                    <a
                      className="btn btn-icon bg-dark-o-50 btn-sm h-30px w-30px mr-0 ml-3"
                      onClick={() => {
                        history.push(
                          `/social-post-v2/${item.campaignId}/edit-social-post-v2`
                        );
                      }}
                    >
                      <img
                        class="h-15px"
                        src="/media/svg/icons/custom/Icon_edit.svg"
                      />
                    </a>
                  )}
                </div>
                <div
                  className="d-flex justify-content-center cursor-pointer w-100 h-100"
                  onClick={() => {
                    history.push(
                      `/campaigns/list/${item?.campaignId}/view?tab=summary`
                    );
                  }}
                >
                  <i className="far fa-eye fa-4x opacity-1"></i>
                </div>
                <div class="col-12 px-4">
                  <p
                    class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                    title="Create Contents"
                  >
                    {item?.campaignName}
                  </p>
                  <div class="d-flex d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="d-flex align-items-center mb-0">
                        <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                          <span class="text-light font-size-xs">
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon-calendar.svg"
                            />{" "}
                            {DateFormatter(item.campaignClosureTime)}
                          </span>
                        </div>
                      </div>
                    </div>
                    {item?.rewardModel === "CASH" && (
                      <div class="flex-shrink-0">
                        <span class="number text-theme font-weight-Bold font-size-h3">
                          {item?.productCurrency == "INR" ? (
                            <img
                              class="mt-n1 mr-1"
                              src="/media/custom/Icon_rupee_sign.svg"
                            />
                          ) : (
                            <img
                              class="mt-n1 mr-1"
                              src="/media/custom/Icon_dollar.svg"
                            />
                          )}
                          {nFormatter(item?.productValue)}
                        </span>
                      </div>
                    )}
                    {item?.rewardModel === "PRODUCT" && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="group-edit-tooltip">
                            {item?.rewardProductDescription}
                          </Tooltip>
                        }
                      >
                        <span className="svg-icon svg-icon-md svg-icon-secondary">
                          <a
                            href="#"
                            class="btn bg-custom-primary btn-pill btn-sm"
                          >
                            Gift
                          </a>
                        </span>
                      </OverlayTrigger>
                    )}
                    {item?.rewardModel === "OFFER" && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="group-edit-tooltip">
                            {item?.rewardProductDescription}
                          </Tooltip>
                        }
                      >
                        <span className="svg-icon svg-icon-md svg-icon-secondary">
                          <a
                            href="#"
                            class="btn bg-custom-primary btn-pill btn-sm"
                          >
                            Offer
                          </a>
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        campaignList?.length === 0 && (
          <div class="card-body pt-0 px-5 pb-0">
            <div class="row no_data_campaign text-center mx-0 pb-5">
              <h6 class="mx-auto font-weight-bold px-5 w-100 mb-0 mt-6 text-dark-50 d-none">
                No Active campaigns yet
              </h6>
              <img
                class="mt-1 mr-1 w-100 gray_scale"
                src="/media/custom/no_campaign.svg"
              />
            </div>
          </div>
        )
      )} */}
    </div>
  );
}
