import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@material-ui/core";
import { Delete, Info, Message, MoreVert } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";

/**
 * @param {{request:function,approved:boolean}} props
 */
export const RequestCard = ({ request, approved }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="w-100 bg-white shadow-sm rounded p-4 d-flex justify-content-between align-items-center">
      <div className=" d-flex flex-column gap-3">
        <div className=" d-flex flex-column ">
          <span className="title2">{request.projectType}</span>
          <span className="description opacity-70">
            {moment(request.dueDate).format("DD MMMM YYYY")}
          </span>
        </div>
        <div
          className={`Lozenge ${approved ? "scheduled-outlined" : "outlined"}`}
        >
          {request.status}
        </div>
      </div>
      <IconButton
        style={{
          height: "40px",
          width: "40px"
        }}
        id="moreButton"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert
          style={{
            fontSize: "14px"
          }}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon
            style={{
              minWidth: "40px"
            }}
          >
            <Info />
          </ListItemIcon>
          <ListItemText>Preview</ListItemText>
        </MenuItem>
        {approved && (
          <MenuItem onClick={handleClose}>
            <ListItemIcon
              style={{
                minWidth: "40px"
              }}
            >
              <Message />
            </ListItemIcon>
            <ListItemText>Chat with Creator</ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon
            style={{
              minWidth: "40px",
              color: "#F44336",
              opacity: "0.8"
            }}
          >
            <Delete />
          </ListItemIcon>
          <ListItemText
            style={{
              color: "#F44336"
            }}
          >
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
