import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const [isUserProfile, setIsUserProfile] = useState(false);
  useEffect(() => {
    if (window.location.pathname === "/user-profile/personal-information") {
      setIsUserProfile(true);
    } else {
      setIsUserProfile(false);
    }
  }, []);
  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <>
      {isUserProfile ? (
        <></>
      ) : (
        <div
          className={`footer py-6 px-10 d-flex flex-lg-column bg-primary  ${layoutProps.footerClasses}`}
          id="kt_footer"
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column align-items-start gap-2">
              <img
                alt="Logo"
                src="/media/logo/white.svg"
                class="max-h-30px mr-8 mt-2"
              />
              <span className="description2 opacity-60 text-light">
                2024-2025 &copy; All Rights Reserved. DMSG India
              </span>
            </div>
            <div className="text-light d-flex flex-row align-items-center ">
              <a
                href="https://1000x.club/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-light body"
              >
                About
              </a>
              <span className="body px-2">|</span>
              <a
                href="https://1000x.club/"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-light body"
              >
                Team
              </a>
              <span className="body px-2">|</span>

              <a
                href="https://1000x.club/"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-light body"
              >
                Contact
              </a>
            </div>
          </div>

          <div className="d-none">
            <div
              className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
            >
              <div className="text-dark order-2 order-md-1">
                <span className="text-muted font-weight-bold mr-2">
                  2020-2024 &copy; All Rights Reserved. DMSG India
                </span>
              </div>
              <div className="nav nav-dark order-1 order-md-2">dasdasd</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
