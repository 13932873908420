import React from "react";
import "./CustomStyles.css"; // Import custom CSS for styling
import { Search } from "@material-ui/icons";
import Customers from "./Customers";

export default function SearchSection() {
  return (
    <div className="search-hero-section position-relative">
      <div className="heroOverlay"></div>

      <div className="hero-content text-center d-flex align-items-center flex-column gap-3">
        <div className="d-flex text-center align-items-center flex-column">
          <h1 class="bold mx-0 text-light pt-0">
            What your business wants today?
          </h1>
          <p class="subheading text-light">
            Discover mWorld: Your One-Stop Marketing Shop for All Your Needs!
          </p>
        </div>
        <a href="#services" style={{ textDecoration: "none" }}>
          <div className="search-box ">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.54189 13.3502C4.77306 13.3502 3.27597 12.7381 2.05064 11.5139C0.825474 10.2896 0.212891 8.79378 0.212891 7.02644C0.212891 5.25894 0.824974 3.7627 2.04914 2.5377C3.27347 1.3127 4.76931 0.700195 6.53664 0.700195C8.30414 0.700195 9.80039 1.31278 11.0254 2.53795C12.2504 3.76328 12.8629 5.26036 12.8629 7.02919C12.8629 7.74319 12.7504 8.42311 12.5254 9.06895C12.3004 9.71478 11.9921 10.2835 11.6004 10.7752L17.1409 16.3037C17.3222 16.4847 17.4129 16.7044 17.4129 16.9627C17.4129 17.221 17.3212 17.4419 17.1379 17.6252C16.9546 17.8085 16.7337 17.9002 16.4754 17.9002C16.2171 17.9002 15.9972 17.8096 15.8159 17.6284L10.2794 12.0877C9.78506 12.4877 9.21706 12.7981 8.57539 13.0189C7.93372 13.2398 7.25589 13.3502 6.54189 13.3502ZM6.53789 11.4752C7.77956 11.4752 8.83164 11.0439 9.69414 10.1814C10.5566 9.31894 10.9879 8.26686 10.9879 7.0252C10.9879 5.78353 10.5566 4.73145 9.69414 3.86895C8.83164 3.00645 7.77956 2.5752 6.53789 2.5752C5.29622 2.5752 4.24414 3.00645 3.38164 3.86895C2.51914 4.73145 2.08789 5.78353 2.08789 7.0252C2.08789 8.26686 2.51914 9.31894 3.38164 10.1814C4.24414 11.0439 5.29622 11.4752 6.53789 11.4752Z"
                fill="white"
              />
            </svg>
            <input type="text" placeholder="Search" className="search-input" />
          </div>
        </a>
      </div>
      <div
        className="bg-white position-absolute rounded-pill shadow-lg z-6"
        style={{ bottom: "24px" }}
      >
        <Customers />
      </div>
    </div>
  );
}
