import React, { useEffect, useState } from "react";
import { LoadingDialog } from "../../_metronic/_partials/controls";

export default function NetworkStatus({ children }) {
  const [speed, setSpeed] = useState(false);
  const getOnLineStatus = () =>
    typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
      ? navigator.onLine
      : true;
  const [status, setStatus] = useState(getOnLineStatus());

  //for fetching the network speed

  const checkInternetSpeed = () => {
    const DOWNLOAD_SIZE = 5245329;
    var download = new Image();
    var startTime = new Date().getTime();
    var cacheBuster = "?nnn=" + startTime;
    download.src =
      "https://1000x-dev-public.s3.amazonaws.com/profilePic/profile_1646045168101_to_1646045323237.jpg" +
      cacheBuster;
    download.id = cacheBuster;
    download.onload = () => {
      var endTime = new Date().getTime();
      showResults(endTime, startTime, DOWNLOAD_SIZE);
    };
    download.onerror = function(err, msg) {
      console.log();
    };
  };

  const showResults = (endTime, startTime, downloadSize) => {
    var duration = (endTime - startTime) / 1000; // ms -> s
    var bitsLoaded = downloadSize * 8;
    var speedBps = (bitsLoaded / duration).toFixed(2);
    var speedKbps = (speedBps / 1024).toFixed(2);
    // var speedMbps = (speedKbps / 1024).toFixed(2);
    if (speedKbps < 300) {
      setSpeed(true);
      setIsLoading(true);
    }
    console.log(speedKbps);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkInternetSpeed();
    }, 180000);
    return () => clearInterval(interval);
  }, []);

  //Setting status to online and offline
  const [isLoading, setIsLoading] = useState(false);

  const useNavigatorOnLine = () => {
    const setOnline = () => {
      setSpeed(false);
      setStatus(true);
      setIsLoading(true);
    };
    const setOffline = () => {
      setSpeed(false);
      setStatus(false);
      setIsLoading(true);
    };

    useEffect(() => {
      let timer = null;
      if (isLoading) {
        timer = setTimeout(() => {
          setIsLoading(false);
        }, 4000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [isLoading]);

    useEffect(() => {
      window.addEventListener("online", setOnline);
      window.addEventListener("offline", setOffline);
      return () => {
        window.removeEventListener("online", setOnline);
        window.removeEventListener("offline", setOffline);
      };
    }, []);
    return status;
  };

  const StatusIndicator = () => {
    const isOnline = useNavigatorOnLine();
    if (speed && isOnline) {
      return <LoadingDialog isLoading={isLoading} text="Slow Internet" />;
    }
    if (isOnline) {
      return <LoadingDialog isLoading={isLoading} text="You Are Online" />;
    } else if (isOnline === false) {
      return <LoadingDialog isLoading={isLoading} text="No Internet" />;
    }
  };

  return (
    <>
      {StatusIndicator()}
    </>
  );
}
